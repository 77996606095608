import React from 'react';
import Helmet from 'react-helmet';
import { Header, Grid, Image } from 'semantic-ui-react';

import Layout from '../components/Layout';

import AboutAri from '../data/about-ari.png';
import Signature from '../data/graphics/signature-static.png';
import '../style/about.css';

export default ({ data, location }) => {
  return (
    <Layout>
      <Helmet
        title={`Ari Hauben Art | About`}
        meta={[
          { name: 'description', content: 'See how Ari makes all of his art.' },
        ]}
      />
      <Header as="h1">ABOUT ARI</Header>
      <Grid stackable>
        <Grid.Column width={8}>
          <div dangerouslySetInnerHTML={{ __html: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_fhaimg0e2j seo=true videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/fhaimg0e2j/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`}} />
        </Grid.Column>
        <Grid.Column width={8} textAlign="left" className="about-text">
          <p>
            Ari Hauben is a contemporary artist based in Boston’s Fort Point neighborhood. His work
            consists of multimedia works, which cover a broad spectrum of topics, styles, and materials.  He
            is a member of the Fort Points Art Community, his art resides in collections all over the country
            and beyond, it can also be found in many public spaces throughout the city of Boston. He has
            participated in many group and solo shows, including being featured in the Grand Opening of the
            Art of Americas Wing at the Museum of Fine Arts.
          </p>
          <p>
            Ari’s current style Ari Hauben is a contemporary artist with a very unique style that he has developed
            through trial and error, happy accidents, and hours and hours of work in his Boston Studio. His style could
            be defined as blending pop and street art techniques into mixed media works. The processes he uses
            involve newspaper, melted crayon, epoxy, spray paint, wood stain and layering techniques that are
            integrated into a variety of visual platforms. Often times he constructs the images within his pieces using
            newspaper print, vintage books, laser cut imagery. This build in meaning while allowing the main image
            to remain the focal point and as the viewer gets closer to the artwork, the words and images begin to
            appear, giving the work added texture and revealing its underlying theme. www.arihauben.com
          </p>
          <p>
            The idea of form meeting function was born out of Ari’s belief that art can be both meaningful
            and aesthetically pleasing, something he embraced while getting his BFA in industrial design
            from RIT. He continued to explore and refine his styles and skill set while getting his MFA from
            BU. As a result of his experiences in both Industrial design and fine arts, Ari believes in not
            limiting himself to any one process or material.  Instead, he simply reaches for a solution that
            feels right for each individual piece of work.
          </p>
          <p>
            In addition to creating compelling art, Ari also wears another hat: art teacher.  Known as “Mr.
            Hauben” to his students, Ari has taught for the past 15 years at a special education Boston Public
            High School designed for students who struggle with emotional and behavioral challenges.
            Exploring and creating art with these dynamic students ranging from 5 th -12th grade, Mr. Hauben
            has seen first-hand the impact of government mandated “educational reforms,” and as a result
            has dedicated his efforts to inspire students to produce art that allow their underutilized creative
            “intelligence” to flourish in the increasingly standardized educational world. In addition to
            working for the Boston Public Schools, Ari has taught, presented, and worked with many
            different institutions in Boston.  These endeavors include developing and leading workshops
            designed for both youth and adult programs at the Institute of Contemporary Art (ICA),
            Children’s Hospital, and other local institutions. He was the Winner of the 2018 Boston Educator of
            the Year Award 2018 from the Boston Public Schools (BPS), the City of Boston, and the Boston Teachers Union (BTU).
          </p>
          <Image src={Signature} width={200} />
        </Grid.Column>
      </Grid>
    </Layout>
  );
};
